body {
  font-family: 'Courier New', monospace;
  background-color: red;
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

img[src*="counter.websiteout"] {
  position: relative;
  left: 50%;
  transform: translate(-90px, 0);
  margin: 10px;
}

h1, h2 {
  font-weight: 400;
}
